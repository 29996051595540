import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { msalInstance } from '../../index';
import client from '../../api/client';
import { loginRequest } from '../../authConfig';

export const userMsalLogin = createAsyncThunk('auth/msalLogin', async () => {
  const response = await msalInstance.loginPopup(loginRequest);

  const idTokenClaims = response.account.idTokenClaims;

  const userType = idTokenClaims['extension_UserType'];

  return userType;
});

export const userLocalLogin = createAsyncThunk(
  'auth/login',
  // Payload creator receives {username, password} object called credentials
  async (credentials) => {
    const response = await client.post('token/', credentials);

    const accessToken = response.data.access;
    const refreshToken = response.data.refresh;

    // Store both access and refresh tokens in local storage
    localStorage.setItem('access', accessToken);
    localStorage.setItem('refresh', refreshToken);

    const userRoleResponse = await client.get('user-type/');
    const role = userRoleResponse.data.is_customer ? 'customer' : 'consultant';

    return role;
  }
);

export const logoutMsal = createAsyncThunk('auth/logoutMsal', async () => {
  await msalInstance.logoutRedirect({
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: '/',
  });

  return null;
});

const Status = Object.freeze({
  idle: 'idle',
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
});

const initialState = {
  status: Status.idle,
  isAuthenticated: false,
  authMethod: 'local', // Local method is default
  userInfo: {
    role: null,
  },
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutLocal(state) {
      // Remove both access and refresh tokens in local storage
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');

      state.isAuthenticated = false;

      state.userInfo = {
        role: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userLocalLogin.pending, (state) => {
        return {
          ...state,
          isAuthenticated: false,
          status: Status.pending,
        };
      })
      .addCase(userLocalLogin.fulfilled, (state, action) => {
        return {
          ...state,
          authMethod: 'local',
          isAuthenticated: true,
          userInfo: {
            ...state.userInfo,
            role: action.payload,
          },
          status: Status.succeeded,
        };
      })
      .addCase(userLocalLogin.rejected, (state, action) => {
        return {
          ...state,
          status: Status.failed,
          error: action.error.message,
        };
      })
      .addCase(userMsalLogin.pending, (state) => {
        return {
          ...state,
          isAuthenticated: false,
          status: Status.pending,
        };
      })
      .addCase(userMsalLogin.fulfilled, (state, action) => {
        return {
          ...state,
          authMethod: 'msal',
          isAuthenticated: true,
          userInfo: {
            ...state.userInfo,
            role: action.payload,
          },
          status: Status.succeeded,
        };
      })
      .addCase(userMsalLogin.rejected, (state, action) => {
        return {
          ...state,
          status: Status.failed,
          error: action.error.message,
        };
      })
      .addCase(logoutMsal.fulfilled, (state) => {
        return {
          ...state,
          isAuthenticated: false,
          userInfo: {
            ...state.userInfo,
            role: null,
          },
        };
      });
  },
});

export const { logoutLocal } = authSlice.actions;
export default authSlice.reducer;
