import { configureStore } from '@reduxjs/toolkit';

import authReducer from './features/auth/authSlice';
import reviewRequestsReducer from './features/review-requests/reviewRequestsSlice';
import assignmentsReducer from './features/assignments/assignmentsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    reviewRequests: reviewRequestsReducer,
    assignments: assignmentsReducer,
  },
});

export default store;
