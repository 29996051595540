import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

const Status = Object.freeze({
  idle: 'idle',
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
});

const initialState = {
  reviewRequests: [],
  status: Status.idle,
  error: null,
};

export const fetchReviewRequests = createAsyncThunk(
  'reviewRequests/fetchReviewRequests',
  async () => {
    const response = await client.get('review-requests/');
    return response.data;
  }
);

export const addNewReviewRequest = createAsyncThunk(
  'reviewRequests/addNewReviewRequest',
  async ({ initialReviewRequest, files }) => {
    // We send the initial data to the API server
    const response = await client.post(
      'review-requests/',
      initialReviewRequest
    );

    // Create associated file attachments in the backend
    files.forEach(async (file) => {
      const requestId = response.data.id;

      const fileObj = {
        file_name: file.name,
        review_request_id: requestId,
      };

      await client.post('file-customer/', fileObj);
    });

    // The response includes the complete review request object
    return response.data;
  }
);

export const updateReviewRequest = createAsyncThunk(
  'reviewRequests/updateReviewRequest',
  async ({ reviewRequestId, formData, files }) => {
    const response = await client.patch(
      `review-requests/update/${reviewRequestId}/`,
      formData
    );

    if (files) {
      files.forEach(async (file) => {
        const fileObj = {
          file_name: file.name,
          review_request_id: reviewRequestId,
        };

        await client.post('file-customer/', fileObj);
      });
    }

    // The response includes the updated review request object
    return response.data;
  }
);

export const publishReviewRequest = createAsyncThunk(
  'reviewRequests/publishReviewRequest',
  async ({ reviewRequestId }) => {
    // Get current date
    const date = new Date();

    // Convert date into this format: YYYY-MM-DD
    const ISO = date.toISOString().split('T')[0];

    const response = await client.patch(
      `review-requests/update/${reviewRequestId}/`,
      {
        is_published: true,
        published_date: ISO,
      }
    );

    // The response includes the published review request object
    return response.data;
  }
);

const reviewRequestsSlice = createSlice({
  name: 'reviewRequests',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchReviewRequests.pending, (state) => {
        state.status = Status.pending;
      })
      .addCase(fetchReviewRequests.fulfilled, (state, action) => {
        state.status = Status.succeeded;
        // Add any fetched review requests to the array
        state.reviewRequests = state.reviewRequests.concat(action.payload);
      })
      .addCase(fetchReviewRequests.rejected, (state, action) => {
        state.status = Status.failed;
        state.error = action.error.message;
      })
      .addCase(addNewReviewRequest.pending, (state) => {
        state.status = Status.pending;
      })
      .addCase(addNewReviewRequest.fulfilled, (state, action) => {
        state.status = Status.succeeded;
        state.reviewRequests.push(action.payload);
      })
      .addCase(updateReviewRequest.pending, (state) => {
        state.status = Status.pending;
      })
      .addCase(updateReviewRequest.fulfilled, (state, action) => {
        state.status = Status.succeeded;

        const { id } = action.payload;

        const reviewRequest = state.reviewRequests.find(
          (reviewRequest) => reviewRequest.id === id
        );

        Object.assign(reviewRequest, action.payload);
      })
      .addCase(publishReviewRequest.pending, (state) => {
        state.status = Status.pending;
      })
      .addCase(publishReviewRequest.fulfilled, (state, action) => {
        state.status = Status.succeeded;
        const { id } = action.payload;

        const reviewRequest = state.reviewRequests.find(
          (reviewRequest) => reviewRequest.id === id
        );

        Object.assign(reviewRequest, action.payload);
      });
  },
});

export default reviewRequestsSlice.reducer;

export const selectAllReviewRequests = (state) =>
  state.reviewRequests.reviewRequests;

export const selectReviewRequestById = (state, reviewRequestId) =>
  state.reviewRequests.reviewRequests.find(
    (reviewRequest) => reviewRequest.id === reviewRequestId
  );

export const selectReviewRequestByCustomerId = (state, customerId) =>
  state.reviewRequests.reviewRequests.find(
    (reviewRequest) => reviewRequest.customer === customerId
  );
