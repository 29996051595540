import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

function AuthGuard({ allowedRoles }) {
  const { isAuthenticated, userInfo } = useSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to='/' />;
  }

  if (!allowedRoles.includes(userInfo.role)) {
    return <Navigate to='/unauthorized' />;
  }

  return <Outlet />;
}

export default AuthGuard;
