import { useState } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { locale } from 'primereact/api';
import client from './api/client';
import AuthGuard from './components/AuthGuard';

function App() {
  const [language, setLanguage] = useState({
    name: 'English',
    code: 'en',
  });

  const handleLanguageChange = (language) => {
    locale(language.code);
    setLanguage(language);
  };

  const handleCreateVendor = async (formData) => {
    try {
      const response = await client.post('vendor-contact-details/', formData);

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  };

  // This receives dirty fields (only modified fields)
  const handleUpdateReviewForm = async (formData) => {
    try {
      let formItems = [];
      let domainEvals = [];
      let globalEval;

      for (const [key, value] of Object.entries(formData)) {
        // Check if it is a form item, evaluation per domain, or a global evaluation
        if (key.includes('formItem')) {
          // First replace the prefix so only the id is left
          const formItemId = key.replace('formItem', '');

          const formItemObj = {
            id: formItemId,
            text: value,
          };

          formItems.push(formItemObj);
        }

        if (key.includes('domainEvaluation')) {
          const domainEvalId = key.replace('domainEvaluation', '');

          const domainEvalObj = {
            id: domainEvalId,
            comments: value,
          };

          domainEvals.push(domainEvalObj);
        }

        if (key.includes('domainRisk')) {
          const domainEvalId = key.replace('domainRisk', '');

          // Check if a domain eval object with the id already exists or if domainEvals array is empty
          const found = domainEvals.find((obj) => obj.id === domainEvalId);

          if (found) {
            // If found, just use the existing domain eval object and add a new field to it
            found['risk_score'] = value;
          } else {
            // If no object exists with the id, make a new object
            const domainEvalObj = {
              id: domainEvalId,
              risk_score: value,
            };

            domainEvals.push(domainEvalObj);
          }
        }

        if (key.includes('globalEvaluation')) {
          const globalEvalId = key.replace('globalEvaluation', '');

          globalEval = {
            id: globalEvalId,
            comments: value,
          };
        }

        if (key.includes('globalRisk')) {
          const globalEvalId = key.replace('globalRisk', '');

          // Check if globalEval is undefined
          if (globalEval) {
            // If it exists, just add a new field risk_score
            globalEval['risk_score'] = value;
          } else {
            // If it doesn't exist, assign to a new object
            globalEval = {
              id: globalEvalId,
              risk_score: value,
            };
          }
        }
      }

      // Patch update form items collection if it was modified
      if (formItems.length !== 0) {
        await client.patch('form-items/', formItems);
      }

      // Patch update domain evaluations collection
      if (domainEvals.length !== 0) {
        await client.patch('evaluations-per-domain/', domainEvals);
      }

      // Patch update global evaluation (remove id first from the object)
      if (globalEval) {
        const globalEvalId = globalEval.id;
        delete globalEval['id'];
        await client.patch(`evaluation-comment/${globalEvalId}/`, globalEval);
      }

      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  };

  const handleDownloadAttachment = async (filename) => {
    const response = await client.get(`download/${filename}/`, {
      responseType: 'blob', // Important: Set responseType to 'blob' for file download
    });

    // Create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // Create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // Clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const handleDownloadReport = async (reviewRequestId) => {
    const response = await client.get(`generate-pdf/${reviewRequestId}/`);

    const binaryData = [];
    binaryData.push(response.data);

    // Create file link in browser's memory
    const href = URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    );

    // Create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'report.pdf');
    document.body.appendChild(link);
    link.click();

    // Clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const handleDownloadEvaluationsCSV = async (evaluations) => {
    const response = await client.get(
      `evaluationCsvDownload/${evaluations.id}`,
      {
        responseType: 'blob', // Important: Set responseType to 'blob' for file download
      }
    );

    // Create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // Create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', evaluations.name);
    document.body.appendChild(link);
    link.click();

    // Clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const router = createHashRouter([
    {
      path: '/',
      lazy: () => import('./pages/Login'),
    },
    {
      path: '/',
      async lazy() {
        return {
          element: <AuthGuard allowedRoles={['customer']} />,
        };
      },
      children: [
        {
          path: 'customer',
          async lazy() {
            let { Layout } = await import('./components/layout/Layout');
            return {
              element: <Layout userRole='Customer' />,
            };
          },
          children: [
            {
              path: '/customer/',
              lazy: () => import('./pages/customer/CustomerDashboard'),
            },
            {
              path: 'requests',
              lazy: () => import('./pages/customer/CustomerRequests'),
            },
            {
              path: 'requests/:requestId',
              children: [
                {
                  index: true,
                  async lazy() {
                    let { CustomerReviewRequest, customerReviewRequestLoader } =
                      await import('./pages/customer/CustomerReviewRequest');
                    return {
                      element: (
                        <CustomerReviewRequest
                          onDownloadAttachment={handleDownloadAttachment}
                          onDownloadReport={handleDownloadReport}
                        />
                      ),
                      loader: customerReviewRequestLoader,
                    };
                  },
                },
                {
                  path: 'review-progress',
                  lazy: () => import('./pages/customer/CustomerReviewProgress'),
                },
              ],
            },
            {
              path: 'requests/:requestId/edit',
              async lazy() {
                let { CustomerRequestEdit, customerRequestEditLoader } =
                  await import('./pages/customer/CustomerRequestEdit');
                return {
                  element: (
                    <CustomerRequestEdit onCreateVendor={handleCreateVendor} />
                  ),
                  loader: customerRequestEditLoader,
                };
              },
            },
            {
              path: 'select-type',
              lazy: () => import('./pages/customer/CustomerSelectReviewType'),
            },
            {
              path: 'create',
              async lazy() {
                let { CustomerCreate, customerCreateLoader } = await import(
                  './pages/customer/CustomerCreate'
                );
                return {
                  element: (
                    <CustomerCreate onCreateVendor={handleCreateVendor} />
                  ),
                  loader: customerCreateLoader,
                };
              },
            },
            {
              path: 'team',
              lazy: () => import('./pages/customer/CustomerTeam'),
            },
            {
              path: 'settings',
              async lazy() {
                let { CustomerSettings, customerSettingsLoader } = await import(
                  './pages/customer/CustomerSettings'
                );
                return {
                  element: (
                    <CustomerSettings
                      onDownloadEvaluationsCSV={handleDownloadEvaluationsCSV}
                      onLanguageChange={handleLanguageChange}
                      language={language}
                      onDownloadFile={handleDownloadAttachment}
                    />
                  ),
                  loader: customerSettingsLoader,
                };
              },
            },
          ],
        },
      ],
    },
    {
      path: '/',
      async lazy() {
        return {
          element: <AuthGuard allowedRoles={['consultant']} />,
        };
      },
      children: [
        {
          path: 'consultant',
          async lazy() {
            let { Layout } = await import('./components/layout/Layout');
            return {
              element: <Layout userRole='Consultant' />,
            };
          },
          children: [
            {
              path: '/consultant/',
              lazy: () => import('./pages/consultant/ConsultantDashboard'),
            },
            {
              path: 'cases',
              lazy: () => import('./pages/consultant/ConsultantCases'),
            },
            {
              path: 'settings',
              async lazy() {
                let { ConsultantSettings } = await import(
                  './pages/consultant/ConsultantSettings'
                );
                return {
                  element: (
                    <ConsultantSettings
                      onLanguageChange={handleLanguageChange}
                      language={language}
                    />
                  ),
                };
              },
            },
            {
              path: 'review-requests/:requestId',
              lazy: () => import('./pages/consultant/ConsultantReviewRequest'),
            },
            {
              path: 'reviews/:reviewId',
              async lazy() {
                let { ConsultantReview, consultantReviewLoader } = await import(
                  './pages/consultant/ConsultantReview'
                );
                return {
                  element: (
                    <ConsultantReview
                      onUpdateReviewForm={handleUpdateReviewForm}
                      onDownloadAttachment={handleDownloadAttachment}
                    />
                  ),
                  loader: consultantReviewLoader,
                };
              },
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div className='App'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
