import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../api/client';

const Status = Object.freeze({
  idle: 'idle',
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
});

const initialState = {
  assignments: [],
  status: Status.idle,
  error: null,
};

export const fetchAssignments = createAsyncThunk(
  'assignments/fetchAssignments',
  async () => {
    const response = await client.get('assignment/');
    return response.data;
  }
);

export const addNewAssignment = createAsyncThunk(
  'assignments/addNewAssignment',
  async ({ reviewRequest, domains }) => {
    // Create the assignment
    const assignmentResponse = await client.post('assignment/create/', {
      reviewRequest: reviewRequest.id,
      template_id: reviewRequest.template,
      status: 'active',
      estimated_completion_status: 0,
      time_spent: 0,
    });

    // Store the assignment id for later
    const assignmentId = assignmentResponse.data.id;

    // Update review request status to assigned
    await client.patch(`review-requests/update/${reviewRequest.id}/`, {
      status: 'assigned',
    });

    const templateId = reviewRequest.template;

    // Retrieve all evaluation criterias associated with the template
    const evalCriteriasResponse = await client.get(
      `evaluation-criteria-id/${templateId}/`
    );

    const evalCriterias = evalCriteriasResponse.data.data;

    // Now create a form item for each eval criteria
    let formItems = [];

    evalCriterias.forEach((evalCriteria) => {
      const formItem = {
        evaluation_criteria_copy: evalCriteria,
        assignment_id: assignmentId,
      };

      formItems.push(formItem);
    });

    // Create all form items in the backend
    await client.post('form-item/', formItems);

    // Create evaluation in the backend (unfilled comments for now)
    const evaluationResponse = await client.post('evaluation-comment/', {
      assignment: assignmentId,
    });

    const evaluationId = evaluationResponse.data.id;

    // Create evaluation per domain in the backend (unfilled comments for now)
    domains.forEach(async (domain) => {
      await client.post('evaluation-per-domain/', {
        evaluation: evaluationId,
        domain: domain,
      });
    });

    // The response includes the complete assignment object, including unique ID
    return assignmentResponse.data;
  }
);

export const submitAssignment = createAsyncThunk(
  'assignments/submitAssignment',
  async ({ assignmentId }) => {
    const response = await client.patch(`assignment/${assignmentId}/`, {
      status: 'reviewed',
    });

    return response.data;
  }
);

export const updateAssignment = createAsyncThunk(
  'assignments/updateAssignment',
  async ({ assignmentId, formData }) => {
    const response = await client.patch(
      `assignment/${assignmentId}/`,
      formData
    );

    return response.data;
  }
);

export const addCommentToAssignment = createAsyncThunk(
  'assignments/addCommentToAssignment',
  async ({ assignmentId, comment }) => {
    const response = await client.patch(`assignment/update/${assignmentId}/`, {
      new_content: comment,
    });

    const comments = response.data.freeflow_comments;

    const obj = {
      id: assignmentId,
      freeflow_comments: comments,
    };

    return obj;
  }
);

const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAssignments.pending, (state) => {
        state.status = Status.pending;
      })
      .addCase(fetchAssignments.fulfilled, (state, action) => {
        state.status = Status.succeeded;
        // Add any fetched assignments to the array
        state.assignments = state.assignments.concat(action.payload);
      })
      .addCase(fetchAssignments.rejected, (state, action) => {
        state.status = Status.failed;
        state.error = action.error.message;
      })
      .addCase(addNewAssignment.pending, (state) => {
        state.status = Status.pending;
      })
      .addCase(addNewAssignment.fulfilled, (state, action) => {
        state.status = Status.succeeded;
        state.assignments.push(action.payload);
      })
      .addCase(submitAssignment.fulfilled, (state, action) => {
        const { id } = action.payload;

        const assignment = state.assignments.find(
          (assignment) => assignment.id === id
        );

        Object.assign(assignment, action.payload);
      })
      .addCase(updateAssignment.fulfilled, (state, action) => {
        const { id } = action.payload;

        const assignment = state.assignments.find(
          (assignment) => assignment.id === id
        );

        Object.assign(assignment, action.payload);
      })
      .addCase(addCommentToAssignment.pending, (state) => {
        state.status = Status.pending;
      })
      .addCase(addCommentToAssignment.fulfilled, (state, action) => {
        state.status = Status.succeeded;

        const { id } = action.payload;

        const assignment = state.assignments.find(
          (assignment) => assignment.id === id
        );

        Object.assign(assignment, action.payload);
      });
  },
});

export default assignmentsSlice.reducer;

export const selectAllAssignments = (state) => state.assignments.assignments;

export const selectAssignmentById = (state, assignmentId) =>
  state.assignments.assignments.find(
    (assignment) => assignment.id === assignmentId
  );

export const selectAssignmentByReviewRequestId = (state, reviewRequestId) =>
  state.assignments.assignments.find(
    (assignment) => assignment.reviewRequest === reviewRequestId
  );
