import axios from 'axios';
import { msalInstance } from '../index';
import { SERVER_URL } from '../constants/server-url';
import { protectedResources } from '../authConfig';
import store from '../store';

async function acquireAzureAccessToken() {
  const account = msalInstance.getActiveAccount();

  const accessTokenRequest = {
    scopes: protectedResources.api.scopes.read,
    account: account,
  };

  if (account) {
    const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
    return response.accessToken;
  }

  return null;
}

function getTokenFromLocalStorage() {
  const accessToken = localStorage.getItem('access');
  return accessToken;
}

function getRefreshTokenFromLocalStorage() {
  const refreshToken = localStorage.getItem('refresh');
  return refreshToken;
}

function storeTokenInLocalStorage(token) {
  localStorage.setItem('access', token);
}

function clearAuthenticationState() {
  // Clear access token
  localStorage.removeItem('access');

  // Clear refresh token
  localStorage.removeItem('refresh');
}

const client = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

// Create separate axios instance just for refreshing the token
const refreshTokenAxios = axios.create({ baseURL: SERVER_URL });

client.interceptors.request.use(
  async function (config) {
    let token = null;

    // Get token from local storage if local user or acquire azure token if user signed in using MSA
    if (store.getState().auth.authMethod === 'local') {
      token = getTokenFromLocalStorage();
    }

    if (store.getState().auth.authMethod === 'msal') {
      token = await acquireAzureAccessToken();
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let refreshPromise = null;
const clearPromise = () => (refreshPromise = null);

client.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        if (!refreshPromise) {
          refreshPromise = refreshToken().finally(clearPromise);
        }

        try {
          const access = await refreshPromise;

          storeTokenInLocalStorage(access);

          client.defaults.headers.common['Authorization'] = `Bearer ${access}`;

          // Retry the request
          return client(originalConfig);
        } catch (refreshError) {
          // refresh token has expired return to login
          window.location.href = '/';
          return Promise.reject(refreshError);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

async function refreshToken() {
  try {
    const response = await refreshTokenAxios.post('token/refresh/', {
      refresh: getRefreshTokenFromLocalStorage(),
    });
    return response.data.access;
  } catch (err) {
    clearAuthenticationState();

    throw new Error('token refresh failed');
  }
}

export default client;
